import { captureException } from '@sentry/nextjs';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { ReactElement } from 'react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { minutes } from '~/helpers/time.helper';
import { getPathsFromCMS } from '~/libs/api';
import { useProfile } from '~/libs/queries/auth';
import useSentryUser from '~/libs/queries/auth/hooks/use-sentry-user';
import { useCMSPage, useStaticContent } from '~/libs/queries/bff';
import { prefetchQueries } from '~/libs/queries/prefetch-queries';
import { useBasketInit, useCurrentBasket, useNextDeliveryRefresh, useStoredBasketId } from '~/libs/queries/basket';
import { useRedirectUser } from '~/libs/use-redirect-user';
import useUser, { useImpersonationCheck } from '~/libs/use-user';
import { Basket } from '~/models/basket';
import { DynamicLayout } from '~/page-types/dynamic.layout';
import { PageRender } from '~/page-types/page.render';
import { DynamicPageProps, NextPageWithLayout } from '~/page-types/types';
import ErrorPage from '~/pages/500';
import Loader from '~/shared/loader/loader.component';
import GoogleTagManager from '~/shell/google-tag-manager/google-tag-manager.component';
import { TidioChat } from '~/page-elements/tidio-chat/tidio-chat';
import { useLayoutState } from '~/context/layout.context';
import { useCreditDraftStorageCleanup } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';

export const getStaticPaths: GetStaticPaths = async () => ({
    // Pages not part of paths endpoint will be generated when requested and added to list of paths
    fallback: 'blocking',
    paths: await getPathsFromCMS(),
});

export const getStaticProps: GetStaticProps<DynamicPageProps, PageParams> = async (context) => {
    const { params, locale, defaultLocale } = context;
    const queryClient = new QueryClient();
    const { notFound, error } = await prefetchQueries({ queryClient, locale, defaultLocale, slug: params?.slug });
    const dehydratedState = dehydrate(queryClient);

    // Clear the cache to avoid high memory consumption on server
    queryClient.clear();

    // 500 - Server error. Couldn't fetch data from BFF
    if (error) {
        captureException(error, {
            tags: {
                slug: `${params?.slug}`,
            },
        });

        return {
            revalidate: 10, // revalidate every 10 seconds for server errors
            props: {
                error: true,
            },
        };
    }

    // 404 - Page not found
    if (notFound) {
        return {
            revalidate: minutes(5, true),
            notFound: true,
        };
    }

    // All other pages
    return {
        revalidate: minutes(10, true),
        props: {
            dehydratedState: dehydratedState,
        },
    };
};

const SlugPageWrapper = ({ error }: DynamicPageProps) => {
    if (error) {
        return <ErrorPage />;
    }

    return <SlugPage />;
};

const SlugPage: NextPageWithLayout<DynamicPageProps> = () => {
    const { error } = useCMSPage();
    const { isFallback } = useRouter();
    const { isLoggedIn } = useUser();
    const { selectedDepartment, isCheckout } = useLayoutState();

    const [storedBasketId] = useStoredBasketId();
    const { data: staticContent } = useStaticContent();

    const { data: basket } = useCurrentBasket();

    // Initializes the basket.
    // If a basket id is stored in local storage,
    // a new one will NOT be created.
    useBasketInit({
        action: storedBasketId ? 'keep' : 'create',
        departmentId: selectedDepartment?.id,
    });

    // Cleans up stored credit draft state, when accounts are being switched
    useCreditDraftStorageCleanup();

    // Will handle all cases of redirecting the user to login page or homepage
    useRedirectUser();

    useImpersonationCheck();

    // Will handle fetch of the latest userinfo from identity server
    useProfile();

    useNextDeliveryRefresh({
        basket: basket as Basket.IBasketDto,
    });

    useSentryUser();

    if (isFallback || (isLoggedIn && error?.allowAnonymousUsers === false)) {
        return <Loader fixed pageTitle="Loading..." />;
    }

    // Render page based on the page type in the CMS
    return (
        <>
            <GoogleTagManager>
                <PageRender />
            </GoogleTagManager>
            {isLoggedIn && staticContent?.isChatEnabled ? <TidioChat /> : null}
        </>
    );
};

SlugPageWrapper.getLayout = function getLayout(page: ReactElement) {
    return <DynamicLayout>{page}</DynamicLayout>;
};

export default SlugPageWrapper;

type PageParams = {
    slug: string[];
};
