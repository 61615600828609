import { setUser } from '@sentry/nextjs';
import { User } from '@sentry/types';
import { Session } from 'next-auth';
import { useEffect } from 'react';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import useUser from '~/libs/use-user';
import { Users as UsersTypes } from '~/models/users';

// Set the user on the sentry.io session
const useSentryUser = () => {
    const { user } = useUser();
    const { activeProfile } = useActiveUser();

    useEffect(() => {
        setUser(createSentryUser({ activeProfile, user }));
    }, [activeProfile, user]);
};

type CreateSentryUserOpts = {
    user: Session | null;
    activeProfile?: UsersTypes.IBasicUserInfo;
};

function createSentryUser({ user, activeProfile }: CreateSentryUserOpts): User | null {
    if (!user || !activeProfile) {
        return null;
    }

    return {
        id: user?.profile?.user_id ? String(user.profile.user_id) : undefined,
        email: user?.profile?.email,
        username: user?.profile?.username,
        customer: activeProfile?.customer?.customerId,
    };
}

export default useSentryUser;
